body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body { background: #b2a790 !important; }


@import url('https://fonts.cdnfonts.com/css/durango-western-eroded');
@import url('https://fonts.cdnfonts.com/css/anderson-four-feather-falls');

.font-durango-western-eroded {
    font-family: 'Durango Western Eroded', sans-serif;
}

.font-falls {
font-family: 'Anderson Four Feather Falls', sans-serif;
}


.white-text {
    color: white;
}

.brown-text {
    color: #3d0c02;
}

.brown-img {
    filter: invert(9%) sepia(18%) saturate(1975%) hue-rotate(314deg) brightness(96%) contrast(96%);
}

.btn-info {
    background-color: #deb887 !important;
    color:#FFF !important;
    border-color: #deb887 !important;
}
 
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
    background-color: #2F3E48 !important;
    color:#FFF !important;
    border-color: #31347B !important;
}
